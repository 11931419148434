import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 */
export class OfferUpdateService extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
    this._disbursementMethod = ''
    this._acceptedInsuranceIds = []
  }

  get data () {
    return {
      creditApplicationId: `${this._creditApplicationId}`,
      disbursementMethod: `${this._disbursementMethod}`,
      acceptedInsuranceIds: this._acceptedInsuranceIds,
    }
  }
  
  /**
   * @param  {String} disbursementMethod
   */
  setDisbursementMethod(disbursementMethod) {
    this._disbursementMethod = `${disbursementMethod}`
  }
  
  /**
   * @param  {Array} acceptedInsuranceIds
   */
  setAcceptedInsuranceIds(acceptedInsuranceIds) {
    this._acceptedInsuranceIds = acceptedInsuranceIds
  }
  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send () {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/offer',
      data: self.data
    }))
  }
}
