import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 */
export class OfferService extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
    this._offerId = ''
    this._disbursementMethod = ''
    this._cardNumber = ''
    this._clabe = ''
    this._acceptedInsuranceIds = []
  }

  get data () {
    return {
      creditApplicationId: `${this._creditApplicationId}`,
      offerId: `${this._offerId}`,
      disbursementMethod: `${this._disbursementMethod}`,
      cardNumber: `${this._cardNumber}`,
      clabe: `${this._clabe}`,
      acceptedInsuranceIds: this._acceptedInsuranceIds,
    }
  }

  get dataValidation () {
    return {
      creditApplicationId: `${this._creditApplicationId}`,
      cardNumber: `${this._cardNumber}`,
      clabe: `${this._clabe}`,
    }
  }

  /**
   * @param  {String} offerId
   */
  setOfferId(offerId) {
    this._offerId = `${offerId}`
  }
  
  /**
   * @param  {String} disbursementMethod
   */
  setDisbursementMethod(disbursementMethod) {
    this._disbursementMethod = `${disbursementMethod}`
  }
  
  /**
   * @param  {String} cardNumber
   */
  setCardNumber(cardNumber) {
    this._cardNumber = `${cardNumber}`
  }

  
  /**
   * @param  {String} clabe
   */
  setClabe(clabe) {
    this._clabe= `${clabe}`
  }
  
  /**
   * @param  {Array} acceptedInsuranceIds
   */
  setAcceptedInsuranceIds(acceptedInsuranceIds) {
    this._acceptedInsuranceIds = acceptedInsuranceIds
  }
  
  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send () {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/offer',
      data: self.data
    }))
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  sendAmountRevolvente (amountMax, amountMin, amountContractBefore, amountToDisposed, id) {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/offer/revolvente',
      data: {
        amountMax,
        amountMin, 
        amountContractBefore,
        amountToDisposed,
        id
      }
    }))
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  validateClabe () {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/validations/direct-deposit',
      data: self.dataValidation
    }))
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  getOffer () {
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: '/offer',
      params: {
        creditApplicationId: this._creditApplicationId
      }
    }))
  }

  /**
  * @param {String} creditApplicationId
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
   getOfferRevolvente(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/offer/revolvente`,
      params: {
        creditApplicationId,
      }
    }))
  }
}
