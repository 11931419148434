import React from 'react'

import { ModalInfo } from '@findep/mf-landings-core'

import InfoIcon from '@material-ui/icons/Info';

import ActiveRenovacion from './ActiveRenovacion'
import PendingRenovacion from './PendingRenovacion'


const RenovacionModal = ({
    activeRenovacion,
    handleCloseModalRenovacion,
    handleNextStep,
    oferta,
    ofertas,
    renovacion
}) => {
    const renovationConfig = new Map([
        [true, {
            title: '¡Salda tu deuda!',
            backgroundTitle: 'primary',
            body:
                <PendingRenovacion
                    credito={renovacion.credito}
                    payOff={renovacion.payOff}
                    pendiente={renovacion.pendiente}
                />
        }],
        [false, {
            title: 'Tu renovación',
            backgroundTitle: 'success',
            body:
                <ActiveRenovacion
                    oferta={oferta}
                    ofertas={ofertas}
                    renovacion={renovacion}
                />
        }]
    ])

    const hasPreviousDebt = renovacion.pendiente ? true : false
    
    const { backgroundTitle, body, title } = renovationConfig.get(hasPreviousDebt)

    return (
        <ModalInfo
            autoClose={!activeRenovacion}
            body={body}
            btnLeft={!hasPreviousDebt && "NO ACEPTO"}
            btnRight={!hasPreviousDebt && "ACEPTO"}
            color={backgroundTitle}
            icon={InfoIcon}
            id='renovacion-modal'
            onChange={(data) => handleNextStep(data)}
            onClose={handleCloseModalRenovacion}
            open={activeRenovacion}
            title={title}
            rootCss="
                    @media(min-width: 768px){
                        width: 50%!important;
                        height: auto!import;
                    }
                    @media(min-width: 1024px){
                        width: 40%!important;
                        height: auto!import;
                    }
                    @media(min-width: 1440px){
                        width: 35%!important;
                        height: auto!import;
                    }"
        />
    )
}

export default RenovacionModal
