import React from 'react'
import { Box, Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { green, red } from '@material-ui/core/colors'

const ActiveRenovacion = (props) => {

    const itemText = (text) => {
        return (
            <ListItemText
                primary={
                    <Typography component="div" align="left">
                        <Box fontWeight='fontWeightRegular' mr={1}>
                            {text}:
                        </Box>
                    </Typography>
                }
            />

        )
    }

    const itemValue = (value, color) => {
        const cantidad = new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(value);

        return (
            <ListItemText
                primary={
                    <Typography component="div" align="right">
                        <Box fontWeight='fontWeightBold' fontSize="h6.fontSize" color={color} >
                            {cantidad}
                        </Box>
                    </Typography>
                }
            />
        )
    }

    const greenColor = green[500];
    const redColor = red[500];

    const credito = props.ofertas.find(element => element.id === props.oferta)


    return (
        <Box style={{ marginTop: "-40px" }}>
            {/* <List dense={false}>
                <ListItem >                                        
                    {itemText("Crédito Original")}
                    {itemValue(props.renovacion.credito, greenColor)}                                                                                
                </ListItem>
                <Divider />                                
                <ListItem>                                        
                    {itemText("Saldado")}
                    {itemValue(props.renovacion.saldado, greenColor)}                                                                                
                </ListItem>
                <Divider />                                
                <ListItem>                                        
                    {itemText("PENDIENTE")}                                            
                    {itemValue(props.renovacion.pendiente, redColor)}                                                                                
                </ListItem>
                <Divider />                                                                                            
            </List>                                                                                    */}
            <List dense={false}>
                <ListItem>
                    {itemText("Renovación")}
                    {itemValue(credito.amount, greenColor)}
                </ListItem>

                <Divider />

                <ListItem>
                    <RemoveCircleIcon color='disabled' mr={2} />
                    {itemText("PENDIENTE")}
                    {itemValue(props.renovacion.pendiente, redColor)}
                </ListItem>

                <Divider />

                <ListItem>
                    {itemText("Te depositaremos")}
                    {itemValue((props.renovacion.deposito), greenColor)}
                </ListItem>

                <Divider />

                <ListItem>
                    <Typography component="div" align="left">
                        <Box fontWeight='fontWeightRegular' mr={1}>
                            Monto pendiente
                            <br />
                            (PAYOFF a la fecha):
                        </Box>
                    </Typography>
                    {itemValue((props.renovacion.payOff), greenColor)}
                </ListItem>

                <Divider />
            </List>
        </Box>
    )
}

export default ActiveRenovacion