/** @jsx */
import React, { Component } from 'react'
import {
    CardElevation,
} from '@findep/mf-landings-core';
import {
    Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Arcoiris from '../elements/Arcoiris';

import formatNumber from '../../helpers/formatNumber'
import NameCompany from '../../helpers/NameCompany'
import propTypes from 'prop-types'

class ArcoirisCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            score: this.props.score,
            color: this.props.color,
            monto: this.props.monto,
            company: NameCompany(this.props.company)
        }
    }

    render() {
        return (
            <div>
                <CardElevation>
                    <Grid item style={{ align: "center" }}>
                        <Arcoiris color={this.state.color} score={this.state.score}/>                        
                    </Grid>
                    <br />
                    <Typography>                        
                        Con base en tu información, y un monto original de {formatNumber(this.state.monto, 3, ',', '$', 0, '')}, {this.state.company.display} te ofrece opciones de crédito en los siguientes términos (selecciona una opción):
                    </Typography>
                </CardElevation>
            </div>

        )
    }
}

ArcoirisCard.propTypes = {
    score: propTypes.number,
    color: propTypes.array,
    monto: propTypes.string,
    company: propTypes.string,
};

export default ArcoirisCard;