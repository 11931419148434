const json = {
    mensajes: {
        VALID: 'La tarjeta o Clabe es válida',
        INVALIDISCREDITCARD: 'No podemos depositar a tarjetas de crédito',
        INVALIDISPREPAID: 'No podemos depositar a tarjetas prepagadas',
        INVALIDBANKNOTSUPPORTED: 'No podemos depositar al banco emisor',
        INVALIDCOUNTRY: 'El país de la tarjeta o CLABE no es válido'
    },
    severity: {
        VALID: 'success',
        INVALIDISCREDITCARD: 'error',
        INVALIDISPREPAID: 'error',
        INVALIDBANKNOTSUPPORTED: 'error',
        INVALIDCOUNTRY: 'error'
    },
    error: {
        VALID: false,
        INVALIDISCREDITCARD: true,
        INVALIDISPREPAID: true,
        INVALIDBANKNOTSUPPORTED: true,
        INVALIDCOUNTRY: true,
    }
}

export const getMessageClabe = (result) => {
    result = result?.toUpperCase()
    return json.mensajes[result]
}

export const getSeverityClabe = (result) => {
    result = result?.toUpperCase()
    return json.severity[result]
}

export const getErrorBooleanClabe = (result) => {
    result = result?.toUpperCase()
    return json.error[result]
}